import React from 'react'
import styles from './Footer.module.css'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className={styles.footer} />
    )
  }
}

export default Footer
