import React from 'react'
import { Link, StaticQuery } from 'gatsby'
import logo from '../img/logo.png'
import styles from './Navbar.module.css'
import { graphql } from 'gatsby'

const Links = () => (
  <StaticQuery
    query={graphql`
      query Links {
        allMarkdownRemark(
          sort: { fields: [frontmatter___position], order: ASC }
          filter: { frontmatter: { key: { eq: "page" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: pages } = data.allMarkdownRemark
      return (
        pages.map((page) =>
          <Link className={'navbar-item ' + styles.navbarItem} key={page.node.id} to={page.node.fields.slug}>
            {page.node.frontmatter.title}
          </Link>
        )
      )
    }}
  />
)

const Navbar = class extends React.Component {
  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <Link to="/" className={styles.logo} title="Logo">
          <img className={styles.logoImage} src={logo} alt="Smangtasia" />
        </Link>

        <div className={styles.right} >
          <div className={styles.announce}>
            <span className={styles.title}>Smangtastia II</span>
            <span className={styles.date}>July 26-28, 2019</span>
          </div>

          <div className={styles.menu}>
            <Links />
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
