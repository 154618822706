import React from 'react'
import Layout from '../components/Layout'
import RATicketEmbed from '../components/RATicketEmbed'
import Helmet from 'react-helmet'
import { HTMLContent } from '../components/Content'
import { graphql } from 'gatsby'

class TicketsPage extends React.Component {
  render() {
    const content = this.props.data.markdownRemark.html

    return (
      <>
        <Helmet>
          <title>Smangtasia - Tickets</title>
          <meta property="og:title" content="Smangtasia - Tickets" />
        </Helmet>
        <Layout>
          <section className="page-content">
            <HTMLContent content={content} />
          </section>
          <RATicketEmbed eventID="1238138" />
        </Layout>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title }}) {
      html
      frontmatter {
        title
        metaKeywords
        metaDescription
      }
    }
  }
`

export default TicketsPage
