import React from 'react'
import styles from './iframe.module.css'

const base = function (eventID) {
  return `https://www.residentadvisor.net/widget/event/${eventID}/tickets?bgcolor=e8c5a6`
}

const theme = {
  bgcolor: "ffffff",
  linkcolor: "6b96c2",
  textcolor: "000000",
  darkbuttons: "true"
}

const iframeSrc = function (eventID, theme) {
  return base(eventID) + paramsString(theme)
}

const paramsString = function (theme) {
  return Object.keys(theme).map(function (x){
    return x + '=' + theme[x]
  }).join('&')
}

export default function RATicketEmbed (props) {
  return (
    <div className={styles.iframeWrapperTall} >
      <iframe
        className={styles.iframe}
        title="RA Ticket Embed"
        src={iframeSrc(props.eventID, theme)}
      />
    </div>
  )
}
